<script setup>

import {nextTick, onMounted, ref} from 'vue'
import {useForm} from "@inertiajs/vue3";

import DialogModal from "@/Components/DialogModal.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";

import FormInput from "@/Form/FormInput.vue";
import {logError} from "@/utils.js";

const props = defineProps({
});

const form = useForm({
    clave: null
});

const showDlg = ref(false);

let onClose = null;

const open = (data, callback) => {

    onClose = callback;

    showDlg.value = true;
};

const save = () => {

    form.processing = true;

    window.location = "/caso/" + form.clave;

};

const close = () => {
    showDlg.value = false;
    form.reset();
};

onMounted(() => {
});

defineExpose({open});

</script>

<template>

    <DialogModal :show="showDlg" @close="close" max-width="xl">

        <template #title>
            Seguimiento de caso
        </template>

        <template #content>

            <div class="space-y-3">

                <p class="">Introduzca o pegue la clave que se le facilito cuando creó el caso</p>

                <div class="w-full">

                    <FormInput label="" v-model="form.clave"
                               class="w-full"/>
                </div>

            </div>

        </template>

        <template #footer>
            <SecondaryButton @click="close">
                Cancelar
            </SecondaryButton>

            <PrimaryButton
                class="ms-3"
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                @click="save">
                Buscar
            </PrimaryButton>
        </template>

    </DialogModal>

</template>

<style scoped>
</style>
